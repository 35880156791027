import React, { useState } from "react"
import about from "../assets/images/about us.png"
import Layout from "../components/navigation/layout"
import Seo from "../components/seo"
import Analyze from "../components/Analyze"

const About = () => {
  const [readMore, setReadMore] = useState(false)
  const extraContent = <div>
    <p><em>IMPROVED CLIENT RELATIONS</em><br />
      Give your client visibility of their cargo while never losing control of what the clients have access to.
    </p>
    <p><em>FRAUD PREVENTION</em><br />
      Prevent fuel card related fraud, reduce incidents of theft.
    </p>
  </div>
  const linkName = readMore ? "Read Less" : "Read More"
  return (
    <Layout>
      <Seo title="About Us" />
      <div className="page-heading header-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>About Us</h1>
              <span>We help organizations run a safer and more efficient fleet</span>
            </div>
          </div>
        </div>
      </div>
      <div className="more-info about-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="more-info-content">
                <div className="row">
                  <div className="col-md-6 align-self-center">
                    <div className="right-content">
                      <span>How it works</span>
                      <h2>Get to know about <em>ELD</em></h2>
                      <div className="readmore">
                        <p><em>INTEGRATED MANAGEMENT CONSOLE</em><br />
                          Increase efficiency by reducing time needed to cross reference data in multiple systems with
                          TrackEnsure integrated management console.</p>
                        <p><em>USER FRIENDLY INTERFACE</em> <br />
                          Reduce dispatcher, CSR and planner training costs with our well designed user interface.
                          Reduce processing errors with our easy to use business management tools.
                        </p>
                        {readMore && extraContent}
                        <button className="filled-button more" onClick={() => {
                          setReadMore(!readMore)
                        }}>{linkName}</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="left-image">
                      <img src={about} alt="about" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Analyze />
    </Layout>
  )
}

export default About